<template>
    <v-container>
        <div class="d-flex flex-column align-center mt-2">
            <div class="d-inline-flex align-center" style="display: block;width: 800px">
                <div>
                    <v-radio-group v-model="langSelection" hide-details row>
                        <v-radio
                                label="ไทย"
                                :value="1"
                        ></v-radio>
                        <v-radio
                                label="한국"
                                :value="2"
                        ></v-radio>
                    </v-radio-group>
                </div>
                <v-spacer></v-spacer>
                <div>
                    <v-btn v-print="printObj" color="primary">สั่งพิมพ์เอกสาร</v-btn>
                </div>
            </div>
            <div id="printMe" class="setfont" v-if="langSelection===2">
                <div v-for="(item,index) in dataPrint" :key="index" class="elevation-4 mt-2 rounded-lg"
                     style="background: #fff; width: 210mm;height: 297mm;padding:30px 30px 30px 30px">
                    <v-row no-gutters>
                        <v-col cols="4">
                            <v-row no-gutters>
                                <v-col cols="12" class="bt-1 bl-1" style="text-align: center;font-weight: bold">
                                    <div class="set-height">
                                        거 래 일 자
                                    </div>
                                </v-col>
                                <v-col cols="12" class="bt-1 bl-1 bb-1 text-center1" style=" text-align: center">
                                    <div class="set-height">
                                        {{ item.create_date_text }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="8" class="b-1 text-center1"
                               style=" text-align: center;font-weight: bold;font-size: 15px">
                            <div class="set-height">
                                <div>
                                    거 래 명 세 서 (공급자용)
                                </div>
                                {{ item.order_code }}
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="bl-1  br-1">
                            <v-row no-gutters>
                                <v-col cols="1" class="br-1" style="text-align: center;font-weight: bold">
                                    <div class="set-height">
                                        <span>공</span>
                                        <br>
                                        <br>
                                        <span>급</span>
                                        <br>
                                        <br>
                                        <span>받</span>
                                        <br>
                                        <br>
                                        <span>는</span>
                                    </div>
                                </v-col>
                                <v-col cols="11">
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 bb-1 text-center1"
                                               style=" text-align: center;font-weight: bold">
                                            <div class="set-height">
                                                상 호
                                            </div>
                                        </v-col>
                                        <v-col cols="10" class="bb-1">
                                            <div class="set-height">
                                                &nbsp;{{ item.customer_lname }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 bb-1 text-center1"
                                               style=" text-align: center;font-weight: bold">
                                            <div class="set-height" style="padding-top:12px">
                                                사 업 장
                                            </div>
                                        </v-col>
                                        <v-col cols="10" class="bb-1" style="padding-left: 3px;font-size: 11px">
                                            <div class="set-height">
                                                {{
                                                    item.order_transportation_full_address
                                                }}
                                            </div>
                                        </v-col>

                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 bb-1 text-center1"
                                               style=" text-align: center; font-weight: bold">
                                            <div class="set-height">
                                                전화번호
                                            </div>
                                        </v-col>
                                        <v-col cols="10" class="bb-1">
                                            <v-row no-gutters>
                                                <v-col cols="5">
                                                    <div class="set-height" style="padding-left: 3px;">
                                                        {{ item.order_transportation_phone }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="2" class="bl-1 br-1" style="">
                                                    <div class="set-height">
                                                        &nbsp; Fax
                                                    </div>
                                                </v-col>
                                                <v-col cols="4">
                                                    <div class="set-height"
                                                         v-if="item.customer_fax && item.customer_fax.length">
                                                        &nbsp;{{ item.customer_fax.substring(0, 10) }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 text-center1"
                                               style=" text-align: center;font-weight: bold">
                                            <div style="padding-top:12px;padding-bottom:12px">
                                                합계금액
                                            </div>
                                        </v-col>
                                        <v-col cols="10" style="font-weight: bold;font-size: 18px">
                                            <div style="padding-top:8px;padding-bottom:6px"> &nbsp;
                                                ₩ {{ item.sum_total_transportation |formatPrice }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-col>
                        <v-col cols="6" class=" br-1">
                            <v-row no-gutters>
                                <v-col cols="1" class="br-1 "
                                       style="font-size: 12px;text-align: center;font-weight: bold">
                                    <div class="set-height">
                                        <br>
                                        <span>공</span>
                                        <br>
                                        <br>
                                        <span>급</span>
                                        <br>
                                        <br>
                                        <span>자</span>
                                        <br>
                                    </div>
                                </v-col>
                                <v-col cols="11">
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 bb-1" style="text-align: center;font-weight: bold">
                                            등 록 번 호
                                        </v-col>
                                        <v-col cols="10" class="bb-1">
                                            <v-row no-gutters>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[0] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[1] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[2] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[3] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[4] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[5] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[6] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[7] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[8] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[9] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[10] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class=" text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[11] }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 bb-1 text-center1"
                                               style=" text-align: center;font-weight: bold">
                                            <div class="set-height">
                                                상 호
                                            </div>
                                        </v-col>
                                        <v-col cols="10" class="bb-1">
                                            <v-row no-gutters>
                                                <v-col cols="6" style="font-size: 9px" class="br-1">
                                                    <div class="set-height">
                                                        &nbsp; {{ shopData.shop_name }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="2" style="font-size: 12px; text-align: center"
                                                       class=" br-1 text-center1">
                                                    <div class="set-height">
                                                        성명
                                                    </div>
                                                </v-col>
                                                <v-col cols="4" style="font-size: 9px" class="">
                                                    <!--                          {{ item.user_create }}-->
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 bb-1 text-center1"
                                               style=" text-align: center;font-weight: bold">
                                            <div class="set-height" style="padding-top:12px">
                                                사 업 장
                                            </div>
                                        </v-col>
                                        <v-col cols="10" class="bb-1" style="padding-left:3px; font-size: 11px">
                                            <div class="set-height">
                                                {{ shopData.shop_full_address }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 text-center1"
                                               style=" text-align: center;font-weight: bold">
                                            <div class="set-height" style="padding-top: 7px">
                                                전 화
                                            </div>
                                        </v-col>
                                        <v-col cols="10">
                                            <v-row no-gutters>
                                                <v-col cols="5">
                                                    <div class="set-height" style="padding-left: 3px;padding-top: 7px">
                                                        {{
                                                            shopData.shop_phone
                                                        }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="2" class="bl-1 br-1">
                                                    <div class="set-height" style="padding-left: 3px;padding-top: 7px">
                                                        Fax
                                                    </div>
                                                </v-col>
                                                <v-col cols="4">
                                                    <div class="set-height" style="padding-left: 3px;padding-top: 7px"
                                                         v-if="shopData.shop_fax_number && shopData.shop_fax_number.length">
                                                        {{ shopData.shop_fax_number.substring(0, 14) }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>

                        <v-col cols="1" class="bb-1 bt-1 bl-1  text-center"
                               style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                월
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                일
                            </div>
                        </v-col>
                        <v-col cols="3" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold">
                            <div class="set-height">
                                품 목
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                규 격
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                수 량
                            </div>
                        </v-col>
                        <v-col cols="2" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                단 가
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                공급가액
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                세 액
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bt-1 bl-1 text-center br-1" style="font-weight: bold">
                            <div class="set-height">
                                비 고
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-for="i in item.order_detail_vat_arr">
                        <v-col cols="1" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[0] }}</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[1] }}</div>
                        </v-col>
                        <v-col cols="3" class="bb-1 bl-1 text-center">
                            <div class="set-height2">{{ i[2] }}</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[3] }}</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[4] }}</div>
                        </v-col>
                        <v-col cols="2" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[5] }}</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[6] }}</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[7] }}</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 br-1 text-center">&nbsp;{{ i[8] }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1" class="bb-1 bl-1" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                공급 가액
                            </div>
                        </v-col>
                        <v-col cols="2" class="bb-1 bl-1 br-1">
                            <div class="set-height">
                                &nbsp;{{
                                    item.sum_total_transportation - item.order_vat_value | formatPrice
                                }}
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 " style="font-weight: bold">
                            <div class="set-height" style="text-align: center">세액</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 br-1">
                            <div class="set-height">
                                &nbsp;{{ item.order_vat_value |formatPrice }}
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 " style="font-weight: bold">
                            <div class="set-height" style="text-align: center">
                                합계금액
                            </div>
                        </v-col>
                        <v-col cols="2" class="bb-1 bl-1 br-1">
                            <div class="set-height">
                                &nbsp;{{ item.sum_total_transportation |formatPrice }}
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 " style="font-weight: bold">
                            <div class="set-height" style="text-align: center">
                                미수금
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 br-1">
                            <div class="set-height">
                                &nbsp;-
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 " style="font-weight: bold">
                            <div class="set-height" style="text-align: center">
                                인수자
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 br-1"></v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="12" class="bb-1 bl-1 br-1">
                            <div class="set-height">
                                <span v-if="item.bank_number  && item.bank_number.length">
                                {{ item.bank_number.substring(0, item.bank_number.length - 4) }}xxxx
                                {{ item.bank_full_name }} {{ item.bank_name }}
                                    </span>
                                <span v-else class="ms-4">
                                    -
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cos="12" class="bb-1 bl-1 br-1">
                            <div class="set-height">
                                &nbsp; {{ item.order_transportation_note || '-' }}
                            </div>
                        </v-col>
                    </v-row>
                    <br>
                </div>
            </div>
            <div id="printMe" class="setfont" v-if="langSelection===1">
                <div v-for="(item,index) in dataPrint" :key="index" class="elevation-4 mt-2 rounded-lg"
                     style="background: #fff; width: 210mm;height: 297mm;padding:30px 30px 30px 30px">
                    <v-row no-gutters>
                        <v-col cols="4">
                            <v-row no-gutters>
                                <v-col cols="12" class="bt-1 bl-1" style="text-align: center;font-weight: bold">
                                    <div class="set-height">
                                        วันที่
                                    </div>
                                </v-col>
                                <v-col cols="12" class="bt-1 bl-1 bb-1 text-center1" style=" text-align: center">
                                    <div class="set-height">
                                        {{ item.create_date_text }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="8" class="b-1 text-center1"
                               style=" text-align: center;font-weight: bold;font-size: 15px">
                            <div class="set-height">
                                <div>
                                    เลขออเดอร์
                                </div>
                                {{ item.order_code }}
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="bl-1  br-1">
                            <v-row no-gutters>
                                <v-col cols="1" class="br-1" style="text-align: center;font-weight: bold">
                                    <div class="set-height">
                                        <br>
                                        <span>ข้อ</span>
                                        <br>
                                        <br>
                                        <span>มูล</span>
                                        <br>
                                        <br>
                                        <span>ลูก</span>
                                        <br>
                                        <br>
                                        <span>ค้า</span>
                                    </div>
                                </v-col>
                                <v-col cols="11">
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 bb-1 text-center1"
                                               style=" text-align: center;font-weight: bold">
                                            <div class="set-height">
                                                ชื่อลูกค้า
                                            </div>
                                        </v-col>
                                        <v-col cols="10" class="bb-1">
                                            <div class="set-height">
                                                &nbsp;{{ item.customer_lname }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 bb-1 text-center1"
                                               style=" text-align: center;font-weight: bold">
                                            <div class="set-height" style="padding-top:12px">
                                                ที่อยู่
                                            </div>
                                        </v-col>
                                        <v-col cols="10" class="bb-1" style="padding-left: 3px;font-size: 11px">
                                            <div class="set-height">
                                                {{
                                                    item.order_transportation_full_address
                                                }}
                                            </div>
                                        </v-col>

                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 bb-1 text-center1"
                                               style=" text-align: center; font-weight: bold">
                                            <div class="set-height">
                                                เบอร์มือถือ
                                            </div>
                                        </v-col>
                                        <v-col cols="10" class="bb-1">
                                            <v-row no-gutters>
                                                <v-col cols="5">
                                                    <div class="set-height"
                                                         style="padding-left: 3px;padding-top:15px;padding-bottom:15px">
                                                        {{ item.order_transportation_phone }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="2" class="bl-1 br-1" style="">
                                                    <div class="set-height"
                                                         style="padding-left: 3px;padding-top:15px;padding-bottom:15px;text-align: center">
                                                        Fax
                                                    </div>
                                                </v-col>
                                                <v-col cols="4">
                                                    <div class="set-height"
                                                         v-if="item.customer_fax  && item.customer_fax.length"
                                                         style="padding-left: 3px;padding-top:15px;padding-bottom:15px">
                                                        &nbsp;{{ item.customer_fax.substring(0, 10) }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 text-center1"
                                               style=" text-align: center;font-weight: bold">
                                            <div style="padding-top:12px;padding-bottom:12px">
                                                ยอดรวม
                                            </div>
                                        </v-col>
                                        <v-col cols="10" style="font-weight: bold;font-size: 18px">
                                            <div style="padding-top:10px;padding-bottom:8px"> &nbsp;
                                                ₩ {{ item.sum_total_transportation |formatPrice }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-col>
                        <v-col cols="6" class=" br-1">
                            <v-row no-gutters>
                                <v-col cols="1" class="br-1 " style="text-align: center;font-weight: bold">
                                    <div class="set-height">
                                        <br>
                                        <span>ข้อ</span>
                                        <br>
                                        <br>
                                        <span>มูล</span>
                                        <br>
                                        <br>
                                        <span>ร้าน</span>
                                        <br>
                                        <br>
                                        <span>ค้า</span>
                                    </div>
                                </v-col>
                                <v-col cols="11">
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 bb-1" style="text-align: center;font-weight: bold">
                                            ทะเบียนการค้า
                                        </v-col>
                                        <v-col cols="10" class="bb-1">
                                            <v-row no-gutters>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[0] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[1] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[2] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[3] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[4] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[5] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[6] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[7] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[8] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[9] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class="br-1 text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[10] }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="1" class=" text-center1" style=" text-align: center">
                                                    <div class="set-heightx">
                                                        {{ shopData.shop_tax_number[11] }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 bb-1 text-center1"
                                               style=" text-align: center;font-weight: bold">
                                            <div class="set-height">
                                                ชื่อบริษัท
                                            </div>
                                        </v-col>
                                        <v-col cols="10" class="bb-1">
                                            <v-row no-gutters>
                                                <v-col cols="6" style="font-size: 9px" class="br-1">
                                                    <div class="set-height">
                                                        &nbsp; {{ shopData.shop_name }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="2" style="font-size: 12px; text-align: center"
                                                       class=" br-1 text-center1">
                                                    <div class="set-height">
                                                        ตัวแทน
                                                    </div>
                                                </v-col>
                                                <v-col cols="4" style="font-size: 9px" class="">
                                                    <!--                          {{ item.user_create }}-->
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 bb-1 text-center1"
                                               style=" text-align: center;font-weight: bold">
                                            <div class="set-height" style="padding-top:12px">
                                                ที่อยู่
                                            </div>
                                        </v-col>
                                        <v-col cols="10" class="bb-1 pt-1" style="padding-left:3px; font-size: 11px">
                                            <div class="set-height">
                                                {{ shopData.shop_full_address }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="2" class="br-1 text-center1"
                                               style=" text-align: center;font-weight: bold">
                                            <div class="set-height">
                                                เบอร์มือถือ
                                            </div>
                                        </v-col>
                                        <v-col cols="10">
                                            <v-row no-gutters>
                                                <v-col cols="5">
                                                    <div class="set-height"
                                                         style="padding-left: 3px;padding-top:15px;padding-bottom:15px">
                                                        {{ shopData.shop_phone }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="2" class="bl-1 br-1">
                                                    <div class="set-height"
                                                         style="padding-left: 3px;padding-top:15px;padding-bottom:15px">
                                                        Fax
                                                    </div>
                                                </v-col>
                                                <v-col cols="4">
                                                    <div class="set-height"
                                                         v-if="item.shop_fax_number  && item.shop_fax_number.length"
                                                         style="padding-left: 3px;padding-top:15px;padding-bottom:15px">
                                                        {{ shopData.shop_fax_number.substring(0, 14) }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>

                        <v-col cols="1" class="bb-1 bt-1 bl-1  text-center"
                               style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                เดือน
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                วันที่
                            </div>
                        </v-col>
                        <v-col cols="3" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold">
                            <div class="set-height">
                                รายการ
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                หน่วย
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                จำนวน
                            </div>
                        </v-col>
                        <v-col cols="2" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                ราคาหน่วย
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                จำนวนเงิน
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bt-1 bl-1 text-center" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                ภาษี
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bt-1 bl-1 text-center br-1" style="font-weight: bold">
                            <div class="set-height">
                                หมายเหตุ
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-for="i in item.order_detail_vat_arr">
                        <v-col cols="1" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[0] }}</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[1] }}</div>
                        </v-col>
                        <v-col cols="3" class="bb-1 bl-1 text-center">
                            <div class="set-height2">{{ i[2] }}</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[3] }}</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[4] }}</div>
                        </v-col>
                        <v-col cols="2" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[5] }}</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[6] }}</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 text-center" style="text-align: center">
                            <div class="set-height2">{{ i[7] }}</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 br-1 text-center">&nbsp;{{ i[8] }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="1" class="bb-1 bl-1" style="font-weight: bold;text-align: center">
                            <div class="set-height">
                                ยอดรวม
                            </div>
                        </v-col>
                        <v-col cols="2" class="bb-1 bl-1 br-1">
                            <div class="set-height">
                                &nbsp;{{
                                    item.sum_total_transportation - item.order_vat_value | formatPrice
                                }}
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 " style="font-weight: bold">
                            <div class="set-height" style="text-align: center">&nbsp;ภาษี</div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 br-1">
                            <div class="set-height">
                                &nbsp;{{ item.order_vat_value |formatPrice }}
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 " style="font-weight: bold">
                            <div class="set-height">
                                &nbsp;ยอดสุทธิ
                            </div>
                        </v-col>
                        <v-col cols="2" class="bb-1 bl-1 br-1">
                            <div class="set-height">
                                &nbsp;{{ item.sum_total_transportation |formatPrice }}
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 " style="font-weight: bold">
                            <div class="set-height">
                                &nbsp;ค้างชำระ
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 br-1">
                            <div class="set-height">
                                &nbsp;-
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 " style="font-weight: bold">
                            <div class="set-height">
                                &nbsp;ลายเซ็นต์
                            </div>
                        </v-col>
                        <v-col cols="1" class="bb-1 bl-1 br-1"></v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="12" class="bb-1 bl-1 br-1">
                            <div class="set-height">
                                <span v-if="item.bank_number  && item.bank_number.length">


                                {{ item.bank_number.substring(0, item.bank_number.length - 4) }}xxxx
                                {{ item.bank_full_name }} {{ item.bank_name }}
                                    </span>
                                <span v-else class="ms-4">
                                    -

                                </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cos="12" class="bb-1 bl-1 br-1">
                            <div class="set-height">
                                &nbsp; {{ item.order_transportation_note || '-' }}
                            </div>
                        </v-col>
                    </v-row>
                    <br>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import print from 'vue-print-nb'
import { onMounted, ref, watch } from "@vue/composition-api"
import { api } from "@/services/api";
import { useRouter } from "@core/utils";
import { formatPrice } from "@/services/filters";

export default {
    directives: {
        print
    },
    props: {},
    components: {},
    filters: {
        formatPrice
    },
    setup(_, { root, emit }) {
        const { route } = useRouter()
        const printLoading = ref(true)
        const dataPrint = ref([])
        const shopData = ref({})
        const langSelection = ref(2)
        const printObj = ref({
            id: "printMe",
            popTitle: '',
            extraCss: 'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap,https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css',
            extraHead: '',
            beforeOpenCallback(vue) {
                vue.printLoading = true
                console.log('打开之前')
            },
            openCallback(vue) {
                vue.printLoading = false
                console.log('执行了打印')
            },
            closeCallback(vue) {
                console.log('关闭了打印工具')
            }
        })
        onMounted(() => {
            shopData.value = JSON.parse(localStorage.getItem('shop'))
            getPrintData('ko')
        })

        const getPrintData = (lang) => {
            api.get({
                path: '/printOrder',
                param: `?order_id_list=${ route.value.query.id }&lang=${ lang }&currency_id=${ localStorage.getItem('currencyId') }`,
            }).then(({ data }) => {
                dataPrint.value = data
                console.log('data:', data[0])
            }).catch((err) => {
                console.log(err)
            })
        }

        watch(langSelection, value => {
            if (value === 1) {
                getPrintData('th')
            } else {
                getPrintData('ko')
            }

        })


        return { printLoading, printObj, dataPrint, langSelection, shopData }
    },


}
</script>

<style scoped>
@media print {
    @page {
        size: 210mm 297mm;
        margin: 0;
    }
}

.setfont {
    font-family: 'Lucida Console';
    font-weight: normal;
    font-style: normal;
    font-size: 13px;
    color: #000;
}

.bl-1 {
    border-left: 1px solid #000;
}

.br-1 {
    border-right: 1px solid #000;
}

.bt-1 {
    border-top: 1px solid #000;
}

.bb-1 {
    border-bottom: 1px solid #000;
}

.b-1 {
    border: 1px solid #000;
}

.set-height {
    padding-top: 5px;
    padding-bottom: 5px;
}

.set-height2 {
    padding-top: 4px;
    padding-bottom: 4px;
}

.set-heightx {
    padding-top: 11px;
    padding-bottom: 11px;
}

@font-face {
    font-family: 'Lucida Console';
    src: url('../assets/font/LucidaConsole.woff2') format('woff2'),
    url('../assets/font/LucidaConsole.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


</style>

